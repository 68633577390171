<template>
    <div class="project-list">
        <div class="project-list-header">
            <CategorySearch :source="filterSource" @change="search" />
            <a-button type="primary" @click="showModal(null)">添加</a-button>
        </div>
        <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
            <template #slotID="{ row }">
                <router-link :to="{
                path: `/IoTManage/ruleDetail/${row.id}`,
                state: { tabName: row.name },
            }">
                    {{ row.bizRuleId }}
                </router-link>
            </template>
        </BasicTable>
    </div>
    <RuleEditModal
      v-if="modalVisible"
      v-model:visible="modalVisible"
      :detail="currentRow"
      @success="editSuccess"
    ></RuleEditModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiRuleManage } from "@/api/IoT/ruleManage.js";
import { apiDictListByCode } from "@/api/common.js";
import { Modal, message } from "ant-design-vue";
import RuleEditModal from "./components/RuleEditModal";
import _ from "lodash";

onMounted(() => {
    tableRef.value.paginationProps.pageSize = 20;
    initTableData();
});

const filterSource = ref([

    {
        label: "规则类型",
        type: "select",
        bindKey: "ruleType",
        options: [],
    },
    {
        label: "启用状态",
        type: "select",
        bindKey: "ruleStatus",
        options: [],
    },
    {
        label: "项目名称关键字",
        type: "input",
        bindKey: "projectName",
        placeholder: "请输入",
    },
    {
        label: "规则名称关键字",
        type: "input",
        bindKey: "name",
        placeholder: "请输入",
    },
]);
const initSearch = async () => {
    apiDictListByCode("RULE_TYPE").then((res) => {
        const _list = _.cloneDeep(filterSource.value);
        _list[0].options = res.result;
        filterSource.value = _list;
    });
    apiDictListByCode("RULE_STATUS").then((res) => {
        const _list = _.cloneDeep(filterSource.value);
        _list[1].options = res.result;
        filterSource.value = _list;
    });
};
initSearch();
let searchData = {};
const search = (data) => {
    searchData = data;
    tableRef.value.paginationProps.current = 1;
    initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
    showIndex: false,
    showSelect: false,
    pagination: true,
    isPageBottom: true,
    remote: false,
    loading: false,
    scroll: {
        scrollToFirstRowOnChange: true,
        y: "calc(100vh - 280px)",
    },
    columns: [
        {
            title: "规则ID",
            type: "customize",
            slotName: "slotID",
        },
        {
            title: "所属项目",
            type: "text",
            key: "projectName",
        },
        {
            title: "规则名称",
            type: "text",
            key: "name",
        },
        {
            title: "规则类型",
            type: "text",
            key: "ruleTypeDesc",
        },
        {
            title: "规则状态",
            type: "text",
            key: "ruleStatusDesc",
        },
        {
            title: "规则描述",
            type: "text",
            key: "ruleDesc",
            ellipsis: true,
        },
    ],
    action: {
        title: "操作",
        width: 100,
        fixed: "right",
        align: "left",
        items: [
            {
                type: "icon",
                icon: "PauseCircleOutlined",
                iconTip: "停用",
                visibleFn: (row) => row.ruleStatus == '01',
                onClick: (row) => {
                    changeStatus(row);
                },
            },
            {
                icon: "CheckCircleOutlined",
                iconTip: "启用",
                type: "icon",
                visibleFn: (row) => row.ruleStatus == '02',
                onClick: (row) => {
                    changeStatus(row);
                },
            },
            {
                type: "icon",
                icon: "FormOutlined",
                iconTip: "修改",
                onClick: (row) => {
                    showModal(row);
                },
            },
            {
                type: "icon",
                icon: "DeleteOutlined",
                iconTip: "删除",
                visibleFn: (row) => row.ruleStatus == '02',
                onClick: (row) => {
                    Modal.confirm({
                        title: "提示",
                        content: "是否删除该条数据？",
                        okType: "danger",
                        onOk: () => {
                            return new Promise((resolve, reject) => {
                                apiRuleManage
                                    .delete(row.id)
                                    .then(() => {
                                        message.success("删除成功");
                                        initTableData();
                                        resolve();
                                    })
                                    .catch(() => {
                                        reject();
                                    });
                            });
                        },
                        onCancel() {
                            console.log("Cancel");
                        },
                    });
                },
            },
        ],
    },
});
const initTableData = async () => {
    const { current, pageSize } = tableRef.value.paginationProps;
    const params = {
        pageNo: current,
        pageSize,
        ...searchData,
    };
    tableConfig.loading = true;
    try {
        const res = await apiRuleManage.page(params);
        const { records, total } = res?.result ?? {};
        tableConfig.loading = false;
        tableRef.value.dataSourceRef = records;
        tableRef.value.paginationProps.total = total;
    } catch (error) {
        tableConfig.loading = false;
        console.log(error);
    }
};
const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex;
    initTableData();
};

const changeStatus = (row) => {
    let m = {
        id: row.id,
        status: row.ruleStatus == '01' ? '02' : '01'
    }
    apiRuleManage.changeStatus(m)
        .then(() => {
            initTableData();
        })
}

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
    currentRow.value = row;
    modalVisible.value = true;
};
const editSuccess = () => {
initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
    height: 100%;
    padding: 16px 20px;
    background: #fff;

    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-table-button {
        line-height: 1;
        height: auto;
    }
}
</style>